<template>
<el-container class="container-main">
	<div class="main-box">
		<div class="invited-log" @click="invitedLogBtn"><img src="@/assets/img/account/invited_log.png" class="icon-log" alt=""> 邀请记录</div>
		<el-form  ref="editForm"
			size="small"
			:model="formLabel"
			label-position="left"
			label-width="140px"
			:rules="rules"
			>
			<div class="form-box">
				<el-form-item label="邀请方式" prop="invite_method">
					<el-radio-group v-model="formLabel.invite_method" @change="getInvitedMethods">
						<el-radio :label="1">定向邀请</el-radio>
						<el-radio :label="2">公开邀请</el-radio>
					</el-radio-group>
				</el-form-item>
				<template v-if="formLabel.invite_method==1">
					<el-form-item label="手机号" prop="invite_contact_mobile">
						<el-input class="form-input" v-model="formLabel.invite_contact_mobile"></el-input>
					</el-form-item>
				</template>
				<template v-if="formLabel.invite_method==2">
					<div class="card-box">
						<invitedCard :key="invited_key" :text="share_content" :code_url="code_url"></invitedCard>
					</div>
				</template>
			</div>
		</el-form>
	</div>
	<el-footer>
		<div class="footer-box" v-if="formLabel.invite_method==1">
			<el-button size="small" class="footer-btn" @click="$router.go(-1)">取消</el-button>
			<el-button size="small" class="footer-btn" type="danger" @click="storeBtn">确定</el-button>
		</div>
	</el-footer>
</el-container>
</template>

<script>
	import Clipboard from 'clipboard'
	import invitedCard from '@/views/mySelf/invitedCompany/components/invitedCard'
	import { validatePhoneTwo } from "@/utils/validate.js";
	export default {
		components: {
			invitedCard,
		},
		data(){
			let vm = this;
			let rules = {
				invite_method:[{required:true,message:'请选择邀请方式'}],
				invite_contact_mobile:[ { required: true, validator: validatePhoneTwo, trigger: "blur" }]
			}
			return {
				headers:{
					title:"邀请用户",
					back: true,
				},
				rules,
				code_img:'',
				imgKey:+ new Date(),
				
				invited_key:0,
				code_url:'',
				share_content:'',
				shareForm:{
					title:'',
					img_path:'',
					info:'',
				},
				visible:false,
				formLabel:{
					invite_method:1,
					invite_type:3,
					invite_place:1,
					invite_contact_mobile:'',
				},
				
				company_name:'',
				userInfo:{},
				invite_info:{},
			}
		},
		created(){
			this.getInvitedSet();
		},
		mounted(){
			this.$store.commit("updatePageNav", [
			  { name: "邀请好友" },
			]);
		},
		methods: {
			invitedLogBtn(){
				this.$router.push({
					path:'/mySelf/invitedUser/invitedLog',	
				})
			},
			typeBtn(data){
				this.formLabel.invite_type = data;
			},
			invitSetBtn(){
				this.visible = true
			},
			getInvitedMethods(type){
				if(type==2 && !this.code_img){
					this.getShareCode();
				}
			},
			shareImgBtn(data){
				this.shareForm.img_path = data.image_url;
			},
			changeMobile(event){
				this.formLabel.invite_contact_mobile = event.target.value;
			},

			editContact(row){
				row.edit = true;
			},
			storeBtn(){
				this.$refs.editForm.validate(valid=>{
					if(valid){
						let data = this.$utils.cloneDeep(this.formLabel);
						let platInfo = JSON.parse(localStorage.platInfo);
						let loginInfo = this.$store.state.loginInfo;
						// console.log('store loginInfo',loginInfo);
						data.invite_contact_mobile = Number(data.invite_contact_mobile);
						if(this.invite_info && this.invite_info.title){
							data.invite_set = {
								...this.invite_info
							}
						}
						this.$axios.postJSON('/base/invite/common',data).then(res=>{
							if(res.code==200){
								this.$message.success("邀请成功");
								this.$router.push({
									path:'/mySelf/invitedUser/invitedLog',	
								})
							}
						})	
					}
				})
			},
			getInvitedSet(){ //获取邀请设置
				let platInfo = JSON.parse(localStorage.platInfo);
				let loginInfo = this.$store.state.loginInfo;
				let company = loginInfo.company;
				// console.log('getInvitedSet loginInfo',loginInfo);
				this.$axios.get('/base/invite/common/set?invite_type=3').then(res=>{
					let data = res.data;
					if(data && data.title){
						this.invite_info = data;
					}else{
						this.invite_info = {
							title:`${loginInfo.user_name}邀请您入驻${platInfo.name}`,
							img_path: platInfo.logo_url,
							info: platInfo.description,
						}
					}
				})
			},
			getShareCode(){
				if(this.code_url){
					return false;
				}
				let params = {
					invite_type:3,
					invite_method:2,
					invite_place:1,
					invite_set:{
						...this.invite_info
					}
				}
				let share_title = this.invite_info.title;
				this.$axios.postJSON('/base/invite/common',params).then(res=>{
					if(res.code == 200){
						let data = res.data;
						this.code_url = data.invite_link;
						this.share_content =  `${share_title}！${data.invite_link}`;
						this.invited_key = +new Date();
					}
				})
			},
		},
	}
</script>

<style scoped lang="scss">
	.container-main{
		width:1200px;
		margin:0 auto;
		background-color: #FFFFFF;
		padding: 0 20px;
		box-sizing: border-box;
	}
	$border:1px solid #EFEFEF;
	$primary:#F26161;
	@mixin ellipsisText($number) {
		display: -webkit-box;
		overflow: hidden;
		-webkit-box-orient: vertical;
		word-break: break-all;
		text-overflow: ellipsis;
		-webkit-line-clamp: $number;
	}
	.radio-group {
		display: flex;
		.radio {
			width: 100px;
			height: 32px;
			display: flex;
			align-items: center;
			justify-content: center;
			border: $border;
			cursor: pointer;
			&:first-child{
				border-radius: 4px 0 0 4px;
			}
			&:last-child{
				border-radius:0 4px 4px 0;
			}
			&.on {
				border: 1px solid $primary;
				color: $primary;
			}
		}
	}
	.main-box{
		position: relative;
		.invited-log{
			position: absolute;
			right:20px;
			top:20px;
			display: flex;
			align-items: center;
			color:#F26161;
			cursor: pointer;
			z-index: 10;
			.icon-log{
				width:16px;
				height: 16px;
				margin-right: 4px;
			}
		}
	}
	.form-box{
		padding-left:30px;
		margin-top:20px;
		.form-input{
			width:350px;
		}
	}
	.forms-table{
		.red-remark{
			color:#F26161;
		}
	}
	.footer-box{
		display:flex;
		justify-content: center;
		.footer-btn{
			width:80px;
			&:last-child{
				margin-left:20px;
			}
		}
	}
	.share-form{
		.form-input{
			width:350px;
		}
	}
	.card-box{
		display: flex;
		padding-left:140px;
		.content{
			width:240px;
			height:246px;
			border:1px solid #efefef;
			padding:10px 12px;
			.title{
				font-weight: 650;
			}
			.info{
				font-size: 13px;
				color:#999999;
			}
			.content1{
				margin-top: 5px;
				word-break:break-all;
				background-color: #FAFAFA;
				padding:10px; 
				line-height: 26px;
				height: 140px;
				@include  ellipsisText(5);
				&.img-code{
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}
			.btn-box{
				margin-top: 8px;
				text-align: right;
				.copy-btn{
					border-color:transparent;
					background-color: rgba($primary,.1);
					color:$primary;
				}
			}
		}
	}
</style>
